import { useLayoutEffect, useRef } from 'react';
import * as L from 'leaflet';
import min from 'lodash/min';
import max from 'lodash/max';
import styled from 'styled-components';

const StyledMap = styled.div`
position: relative;
width: 100%;
height: 300px;
`

type MarkerImage = {
    url: string
    width: number
    height: number
}

type Marker = {
    longitude: number
    latitude: number
    image?: MarkerImage
}

type Props = {
    markers?: Marker[]
}

const LeafletMap = ({ markers }: Props) => {
    const mapRef = useRef<L.Map | null>(null);
    const markerLayerRef = useRef<L.LayerGroup | null>(null);

    useLayoutEffect(() => {
        if (!mapRef.current) {
            const map = L.map('map').setView(L.latLng(51.505, -0.09), 13);
            mapRef.current = map;

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);
        }
    }, []);

    useLayoutEffect(() => {
        const map = mapRef.current;
        if (map && markers) {
            if (markers.length > 0) {
                const minLat = min(markers.map(m => m.latitude)) ?? 0;
                const minLon = min(markers.map(m => m.longitude)) ?? 0;
                const maxLat = max(markers.map(m => m.latitude)) ?? 0;
                const maxLon = max(markers.map(m => m.longitude)) ?? 0;
                map.fitBounds(L.latLngBounds(L.latLng(minLat, minLon), L.latLng(maxLat, maxLon)));
            }

            if (markerLayerRef.current == null) {
                markerLayerRef.current = L.layerGroup().addTo(map);
            } else {
                markerLayerRef.current.clearLayers();
            }
            if (markers.length > 0) {
                L.polyline(markers.map(o => L.latLng(o.latitude, o.longitude))).addTo(markerLayerRef.current);
            }
            for (let marker of markers) {
                const lMarker = L.marker(L.latLng(marker.latitude, marker.longitude)).addTo(markerLayerRef.current);
                if (marker.image) {
                    lMarker.setIcon(L.icon({
                        iconUrl: marker.image.url,
                        iconSize: [marker.image.width, marker.image.height],
                    }))
                }
            }
        }
    }, [markers]);

    return <StyledMap id="map" />
}

export { LeafletMap };

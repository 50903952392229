import { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAuthentication } from 'auth';
import { User } from 'models/User';

const NavBar = () => {
    const [user, setUser] = useState<User | null>(null);
    const { accessToken, login, logout } = useAuthentication();

    useEffect(() => {
        const f = async () => {
            if (accessToken) {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Users/Me`, {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                });
                if (response.ok) {
                    const user = await response.json();
                    setUser(user);
                }
            }
        }
        f();
    }, [accessToken]);
    
    return (
        <Navbar bg="light">
            <Container>
                <Navbar.Brand href='/'>Flight World</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <AuthenticatedTemplate>
                        <NavDropdown title={user?.name}>
                            <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Nav.Link onClick={() => login()}>Login</Nav.Link>
                    </UnauthenticatedTemplate>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;
import React from 'react';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import './index.css';
import ApolloApp from './ApolloApp';
import reportWebVitals from './reportWebVitals';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
const configuration: Configuration = {
    auth: {
        clientId: "9ddebb6e-0a46-4bfb-a0f6-b8bafb8d19e9"
    }
};

const pca = new PublicClientApplication(configuration);

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <MsalProvider instance={pca}>
            <ApolloApp />
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

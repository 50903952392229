import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { useGetFlightsQuery } from 'types';
import { FlightTable } from './FlightTable';

const Layout = ({ children }: { children: ReactNode}) => (
    <Container style={{ marginTop: 20 }}>
        {children}
    </Container>
)

const Flights = () => {
    const { data, loading, error } = useGetFlightsQuery();

    if (loading) {
        return <Layout>Loading...</Layout>;
    }
    if (error || !data) {
        return <Layout>Error!</Layout>
    }

    return (
        <Layout>
            <FlightTable flights={data.flights} />
        </Layout>
    );
};

const HomePage = () => (
    <Flights />
);

export { HomePage };

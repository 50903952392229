import { RefObject, useEffect, useRef } from "react";
import styled from "styled-components";
import 'luminous-lightbox/dist/luminous-basic.css';
import { LuminousGallery } from 'luminous-lightbox';
import { PhotoDisplay } from "./PhotoDisplay";

type Photo = {
    id: string
    uri: string
    title?: string | null | undefined
}

type Props = {
    photos: Photo[]
    onDelete: (photo: { id: string }) => void;
}

const useHorizontalScroll = (): RefObject<HTMLDivElement> => {
    const elRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = (e: WheelEvent) => {
                if (e.deltaY === 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}

const PhotoGallery = ({ photos, onDelete }: Props) => {
    const scrollRef = useHorizontalScroll();
    const luminousRef = useRef<any>(null);

    useEffect(() => {
        if (!luminousRef.current) {
            luminousRef.current = new LuminousGallery(scrollRef.current?.querySelectorAll('.luminous'));
        }
    }, [scrollRef]);

    return (
        <PhotoWrapper ref={scrollRef}>
            {photos.map(photo => (
                <PhotoDisplay
                    key={photo.id}
                    photo={photo}
                    onDelete={onDelete}
                />
            ))}
        </PhotoWrapper>
    )
}

const PhotoWrapper = styled.div`
overflow-x: auto;
white-space: nowrap;
`

export { PhotoGallery };

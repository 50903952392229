import { Container } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { PhotoDisplay } from 'components/common';
import { useGetPhotosQuery } from 'types';

const Photos = () => {
    const { loading, data, error } = useGetPhotosQuery();

    const handleDelete = async ({ id }: { id: string }) => {
        // no-op
    }

    return <div>
        {data?.photos.map(photo => (
            <PhotoDisplay key={photo.id} photo={photo} onDelete={handleDelete} />
        ))}
    </div>
}

const ProfilePage = () => {
    return <Container>
        <AuthenticatedTemplate>
            Hello pilot!
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            Welcome to Flight World!
        </UnauthenticatedTemplate>
        <Photos />
    </Container>
}

export { ProfilePage };

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from 'components/layouts/NavBar';
import { FlightPage } from 'components/modules/flight';
import { ProfilePage } from 'components/modules/profile';
import { HomePage } from 'components/modules/home';

const App = () => (
    <Router>
        <NavBar />

        <Routes>
            <Route path="/flights/:id" element={<FlightPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route index element={<HomePage />} />
        </Routes>
    </Router>
);

export default App;
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  UUID: any;
};

export type AddFlightInput = {
  aircraftTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AddFlightOutput = {
  __typename?: 'AddFlightOutput';
  flight: Flight;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type AuthorizeDirective = {
  __typename?: 'AuthorizeDirective';
  apply: ApplyPolicy;
  policy?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type DeleteFlightInput = {
  flightId: Scalars['UUID'];
};

export type DeleteFlightOutput = {
  __typename?: 'DeleteFlightOutput';
  flightId: Scalars['UUID'];
};

export type DeletePhotoInput = {
  photoId: Scalars['UUID'];
};

export type DeletePhotoOutput = {
  __typename?: 'DeletePhotoOutput';
  photoId: Scalars['UUID'];
};

export type EditFlightDescriptionInput = {
  description: Scalars['String'];
  flightId: Scalars['UUID'];
};

export type EditFlightNameInput = {
  flightId: Scalars['UUID'];
  name: Scalars['String'];
};

export type EditFlightVisibilityInput = {
  flightId: Scalars['UUID'];
  visibility: FlightVisibility;
};

export type Flight = {
  __typename?: 'Flight';
  added: Scalars['DateTime'];
  aircraftTitle?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  permissions: FlightPermissions;
  photos: Array<Photo>;
  updated: Scalars['DateTime'];
  visibility: FlightVisibility;
};

export type FlightPermissions = {
  __typename?: 'FlightPermissions';
  allowsEditing: Scalars['Boolean'];
};

export enum FlightVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Unlisted = 'UNLISTED'
}

export type GetFlightInput = {
  flightId: Scalars['UUID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFlight: AddFlightOutput;
  deleteFlight: DeleteFlightOutput;
  deletePhoto: DeletePhotoOutput;
  editFlightDescription: Flight;
  editFlightName: Flight;
  editFlightVisibility: Flight;
};


export type MutationAddFlightArgs = {
  input: AddFlightInput;
};


export type MutationDeleteFlightArgs = {
  input: DeleteFlightInput;
};


export type MutationDeletePhotoArgs = {
  input: DeletePhotoInput;
};


export type MutationEditFlightDescriptionArgs = {
  input: EditFlightDescriptionInput;
};


export type MutationEditFlightNameArgs = {
  input: EditFlightNameInput;
};


export type MutationEditFlightVisibilityArgs = {
  input: EditFlightVisibilityInput;
};

export type Photo = {
  __typename?: 'Photo';
  added: Scalars['DateTime'];
  flight?: Maybe<Flight>;
  flightId?: Maybe<Scalars['UUID']>;
  height: Scalars['Int'];
  id: Scalars['UUID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated: Scalars['DateTime'];
  uri: Scalars['String'];
  width: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  flight?: Maybe<Flight>;
  flights: Array<Flight>;
  photos: Array<Photo>;
};


export type QueryFlightArgs = {
  input: GetFlightInput;
};

export type GetFlightQueryVariables = Exact<{
  input: GetFlightInput;
}>;


export type GetFlightQuery = { __typename?: 'Query', flight?: { __typename?: 'Flight', id: any, name: string, visibility: FlightVisibility, description: string, aircraftTitle?: string | null, photos: Array<{ __typename?: 'Photo', id: any, added: any, title?: string | null, uri: string, latitude?: number | null, longitude?: number | null }>, permissions: { __typename?: 'FlightPermissions', allowsEditing: boolean } } | null };

export type EditFlightNameMutationVariables = Exact<{
  input: EditFlightNameInput;
}>;


export type EditFlightNameMutation = { __typename?: 'Mutation', editFlightName: { __typename?: 'Flight', id: any, name: string } };

export type EditFlightDescriptionMutationVariables = Exact<{
  input: EditFlightDescriptionInput;
}>;


export type EditFlightDescriptionMutation = { __typename?: 'Mutation', editFlightDescription: { __typename?: 'Flight', id: any, description: string } };

export type EditFlightVisibilityMutationVariables = Exact<{
  input: EditFlightVisibilityInput;
}>;


export type EditFlightVisibilityMutation = { __typename?: 'Mutation', editFlightVisibility: { __typename?: 'Flight', id: any, visibility: FlightVisibility } };

export type DeleteFlightMutationVariables = Exact<{
  input: DeleteFlightInput;
}>;


export type DeleteFlightMutation = { __typename?: 'Mutation', deleteFlight: { __typename?: 'DeleteFlightOutput', flightId: any } };

export type GetFlightsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFlightsQuery = { __typename?: 'Query', flights: Array<{ __typename?: 'Flight', id: any, name: string, aircraftTitle?: string | null, added: any, visibility: FlightVisibility }> };

export type GetPhotosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhotosQuery = { __typename?: 'Query', photos: Array<{ __typename?: 'Photo', id: any, uri: string, title?: string | null }> };

export type DeletePhotoMutationVariables = Exact<{
  input: DeletePhotoInput;
}>;


export type DeletePhotoMutation = { __typename?: 'Mutation', deletePhoto: { __typename?: 'DeletePhotoOutput', photoId: any } };


export const GetFlightDocument = gql`
    query GetFlight($input: GetFlightInput!) {
  flight(input: $input) {
    id
    name
    visibility
    description
    aircraftTitle
    photos {
      id
      added
      title
      uri
      latitude
      longitude
    }
    permissions {
      allowsEditing
    }
  }
}
    `;

/**
 * __useGetFlightQuery__
 *
 * To run a query within a React component, call `useGetFlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlightQuery(baseOptions: Apollo.QueryHookOptions<GetFlightQuery, GetFlightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlightQuery, GetFlightQueryVariables>(GetFlightDocument, options);
      }
export function useGetFlightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlightQuery, GetFlightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlightQuery, GetFlightQueryVariables>(GetFlightDocument, options);
        }
export type GetFlightQueryHookResult = ReturnType<typeof useGetFlightQuery>;
export type GetFlightLazyQueryHookResult = ReturnType<typeof useGetFlightLazyQuery>;
export type GetFlightQueryResult = Apollo.QueryResult<GetFlightQuery, GetFlightQueryVariables>;
export function refetchGetFlightQuery(variables: GetFlightQueryVariables) {
      return { query: GetFlightDocument, variables: variables }
    }
export const EditFlightNameDocument = gql`
    mutation EditFlightName($input: EditFlightNameInput!) {
  editFlightName(input: $input) {
    id
    name
  }
}
    `;
export type EditFlightNameMutationFn = Apollo.MutationFunction<EditFlightNameMutation, EditFlightNameMutationVariables>;

/**
 * __useEditFlightNameMutation__
 *
 * To run a mutation, you first call `useEditFlightNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFlightNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFlightNameMutation, { data, loading, error }] = useEditFlightNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFlightNameMutation(baseOptions?: Apollo.MutationHookOptions<EditFlightNameMutation, EditFlightNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFlightNameMutation, EditFlightNameMutationVariables>(EditFlightNameDocument, options);
      }
export type EditFlightNameMutationHookResult = ReturnType<typeof useEditFlightNameMutation>;
export type EditFlightNameMutationResult = Apollo.MutationResult<EditFlightNameMutation>;
export type EditFlightNameMutationOptions = Apollo.BaseMutationOptions<EditFlightNameMutation, EditFlightNameMutationVariables>;
export const EditFlightDescriptionDocument = gql`
    mutation EditFlightDescription($input: EditFlightDescriptionInput!) {
  editFlightDescription(input: $input) {
    id
    description
  }
}
    `;
export type EditFlightDescriptionMutationFn = Apollo.MutationFunction<EditFlightDescriptionMutation, EditFlightDescriptionMutationVariables>;

/**
 * __useEditFlightDescriptionMutation__
 *
 * To run a mutation, you first call `useEditFlightDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFlightDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFlightDescriptionMutation, { data, loading, error }] = useEditFlightDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFlightDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<EditFlightDescriptionMutation, EditFlightDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFlightDescriptionMutation, EditFlightDescriptionMutationVariables>(EditFlightDescriptionDocument, options);
      }
export type EditFlightDescriptionMutationHookResult = ReturnType<typeof useEditFlightDescriptionMutation>;
export type EditFlightDescriptionMutationResult = Apollo.MutationResult<EditFlightDescriptionMutation>;
export type EditFlightDescriptionMutationOptions = Apollo.BaseMutationOptions<EditFlightDescriptionMutation, EditFlightDescriptionMutationVariables>;
export const EditFlightVisibilityDocument = gql`
    mutation EditFlightVisibility($input: EditFlightVisibilityInput!) {
  editFlightVisibility(input: $input) {
    id
    visibility
  }
}
    `;
export type EditFlightVisibilityMutationFn = Apollo.MutationFunction<EditFlightVisibilityMutation, EditFlightVisibilityMutationVariables>;

/**
 * __useEditFlightVisibilityMutation__
 *
 * To run a mutation, you first call `useEditFlightVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFlightVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFlightVisibilityMutation, { data, loading, error }] = useEditFlightVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFlightVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<EditFlightVisibilityMutation, EditFlightVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFlightVisibilityMutation, EditFlightVisibilityMutationVariables>(EditFlightVisibilityDocument, options);
      }
export type EditFlightVisibilityMutationHookResult = ReturnType<typeof useEditFlightVisibilityMutation>;
export type EditFlightVisibilityMutationResult = Apollo.MutationResult<EditFlightVisibilityMutation>;
export type EditFlightVisibilityMutationOptions = Apollo.BaseMutationOptions<EditFlightVisibilityMutation, EditFlightVisibilityMutationVariables>;
export const DeleteFlightDocument = gql`
    mutation DeleteFlight($input: DeleteFlightInput!) {
  deleteFlight(input: $input) {
    flightId
  }
}
    `;
export type DeleteFlightMutationFn = Apollo.MutationFunction<DeleteFlightMutation, DeleteFlightMutationVariables>;

/**
 * __useDeleteFlightMutation__
 *
 * To run a mutation, you first call `useDeleteFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFlightMutation, { data, loading, error }] = useDeleteFlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFlightMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFlightMutation, DeleteFlightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFlightMutation, DeleteFlightMutationVariables>(DeleteFlightDocument, options);
      }
export type DeleteFlightMutationHookResult = ReturnType<typeof useDeleteFlightMutation>;
export type DeleteFlightMutationResult = Apollo.MutationResult<DeleteFlightMutation>;
export type DeleteFlightMutationOptions = Apollo.BaseMutationOptions<DeleteFlightMutation, DeleteFlightMutationVariables>;
export const GetFlightsDocument = gql`
    query GetFlights {
  flights {
    id
    name
    aircraftTitle
    added
    visibility
  }
}
    `;

/**
 * __useGetFlightsQuery__
 *
 * To run a query within a React component, call `useGetFlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlightsQuery(baseOptions?: Apollo.QueryHookOptions<GetFlightsQuery, GetFlightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlightsQuery, GetFlightsQueryVariables>(GetFlightsDocument, options);
      }
export function useGetFlightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlightsQuery, GetFlightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlightsQuery, GetFlightsQueryVariables>(GetFlightsDocument, options);
        }
export type GetFlightsQueryHookResult = ReturnType<typeof useGetFlightsQuery>;
export type GetFlightsLazyQueryHookResult = ReturnType<typeof useGetFlightsLazyQuery>;
export type GetFlightsQueryResult = Apollo.QueryResult<GetFlightsQuery, GetFlightsQueryVariables>;
export function refetchGetFlightsQuery(variables?: GetFlightsQueryVariables) {
      return { query: GetFlightsDocument, variables: variables }
    }
export const GetPhotosDocument = gql`
    query GetPhotos {
  photos {
    id
    uri
    title
  }
}
    `;

/**
 * __useGetPhotosQuery__
 *
 * To run a query within a React component, call `useGetPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhotosQuery(baseOptions?: Apollo.QueryHookOptions<GetPhotosQuery, GetPhotosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhotosQuery, GetPhotosQueryVariables>(GetPhotosDocument, options);
      }
export function useGetPhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotosQuery, GetPhotosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhotosQuery, GetPhotosQueryVariables>(GetPhotosDocument, options);
        }
export type GetPhotosQueryHookResult = ReturnType<typeof useGetPhotosQuery>;
export type GetPhotosLazyQueryHookResult = ReturnType<typeof useGetPhotosLazyQuery>;
export type GetPhotosQueryResult = Apollo.QueryResult<GetPhotosQuery, GetPhotosQueryVariables>;
export function refetchGetPhotosQuery(variables?: GetPhotosQueryVariables) {
      return { query: GetPhotosDocument, variables: variables }
    }
export const DeletePhotoDocument = gql`
    mutation DeletePhoto($input: DeletePhotoInput!) {
  deletePhoto(input: $input) {
    photoId
  }
}
    `;
export type DeletePhotoMutationFn = Apollo.MutationFunction<DeletePhotoMutation, DeletePhotoMutationVariables>;

/**
 * __useDeletePhotoMutation__
 *
 * To run a mutation, you first call `useDeletePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoMutation, { data, loading, error }] = useDeletePhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePhotoMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotoMutation, DeletePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(DeletePhotoDocument, options);
      }
export type DeletePhotoMutationHookResult = ReturnType<typeof useDeletePhotoMutation>;
export type DeletePhotoMutationResult = Apollo.MutationResult<DeletePhotoMutation>;
export type DeletePhotoMutationOptions = Apollo.BaseMutationOptions<DeletePhotoMutation, DeletePhotoMutationVariables>;
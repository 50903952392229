import { IPublicClientApplication } from '@azure/msal-browser';
import { API_SCOPE } from './constants';

const lookupToken = async (instance: IPublicClientApplication, inProgress: string) => {
    const accounts = instance.getAllAccounts();

    const account = accounts[0];
    if (inProgress === 'none') {
        if (account) {
            try {
                const result = await instance.acquireTokenSilent({
                    scopes: [API_SCOPE],
                    account,
                });
                return result.accessToken;
            } catch (err) {
                // if (err instanceof InteractionRequiredAuthError) {
                //     // fallback to interaction when silent call fails
                //     return instance.acquireTokenRedirect(loginRequest);
                // }
            }
        }
        // We don't force login if the user has not logged in before
    }
    return null;
};

export { lookupToken };
import { Badge, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { FlightVisibility } from "types"

type Props = {
    flights: { 
        id: string
        added: string
        name: string
        aircraftTitle?: string | null | undefined
        visibility: FlightVisibility
    }[]
}

const FlightTable = ({ flights }: Props) => (
    <Table bordered striped>
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th>Flight</th>
                <th>Aircraft</th>
            </tr>
        </thead>
        <tbody>
            {flights.map(flight => (
                <tr id={flight.id}>
                    <td>{new Date(flight.added).toLocaleString()}</td>
                    <StyledLinkCell><Link to={`/flights/${flight.id}`}>{flight.name} <Badge pill>{flight.visibility}</Badge></Link></StyledLinkCell>
                    <td>{flight.aircraftTitle}</td>
                </tr>
            ))}
        </tbody>
    </Table>
)

const StyledLinkCell = styled.td`
padding: 0 !important;
a {
    padding: .5rem;
    text-decoration: none;
    display: block;
    transition: all 0.2s;
}

a:hover {
    background-color: rgba(0, 0, 0, 0.02);
}
`

export { FlightTable }

import { useMemo, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Dropdown, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { buildURL } from 'react-imgix';
import { LeafletMap } from './LeafletMap';
import { FlightVisibility, GetFlightsDocument, useDeleteFlightMutation, useEditFlightDescriptionMutation, useEditFlightNameMutation, useEditFlightVisibilityMutation, useGetFlightQuery } from 'types';
import { PhotoGallery } from 'components/common';

type EditableInputProps<T> = {
    isLoading: boolean
    isEditable: boolean
    value: T
    onChange: (value: T) => void 
}

const Name = ({ isLoading, isEditable, value, onChange }: EditableInputProps<string>) => {
    const [isEditing, setEditing] = useState(false);
    const [editingValue, setEditingValue] = useState('');
    if (isEditing) {
        return (
            <InputGroup>
                <FormControl value={editingValue} onChange={e => setEditingValue(e.target.value)} />
                <Button onClick={() => {
                    setEditing(false);
                    onChange(editingValue);
                }}>Save</Button>
                <Button variant="secondary" onClick={() => setEditing(false)}>Cancel</Button>
            </InputGroup>
        );
    }
    return (
        <h3>{value} {isEditable ? <Button size="sm" variant="secondary" disabled={isLoading} onClick={() => {
            setEditingValue(value);
            setEditing(true);
        }}>Edit</Button> : null}</h3>
    );
}

const Description = ({ isLoading, isEditable, value, onChange }: EditableInputProps<string>) => {
    const [isEditing, setEditing] = useState(false);
    const [editingValue, setEditingValue] = useState('');
    if (isEditing) {
        return (
            <>
                <FormControl as="textarea" value={editingValue} onChange={e => setEditingValue(e.target.value)} />
                <ButtonGroup>
                    <Button onClick={() => {
                        setEditing(false);
                        onChange(editingValue);
                    }}>Save</Button>
                    <Button variant="secondary" onClick={() => setEditing(false)}>Cancel</Button>
                </ButtonGroup>
            </>
        );
    }
    return (
        <p>{value ? value : <em>No description.</em>} {isEditable ? <Button size="sm" variant="secondary" disabled={isLoading} onClick={() => {
            setEditingValue(value);
            setEditing(true);
        }}>Edit</Button> : null}</p>
    );
}

const Visibility = ({ isLoading, isEditable, value, onChange }: EditableInputProps<FlightVisibility>) => isEditable ? 
(
    <Dropdown>
        <Dropdown.Toggle disabled={isLoading}>{value}</Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item active={value === FlightVisibility.Private} onClick={() => onChange(FlightVisibility.Private)}>Private</Dropdown.Item>
            <Dropdown.Item active={value === FlightVisibility.Unlisted} onClick={() => onChange(FlightVisibility.Unlisted)}>Unlisted</Dropdown.Item>
            <Dropdown.Item active={value === FlightVisibility.Public} onClick={() => onChange(FlightVisibility.Public)}>Public</Dropdown.Item>
        </Dropdown.Menu>    
    </Dropdown>
) : (
    <Badge>{value}</Badge>
);

const Content = ({ flightId }: { flightId: string }) => {
    const navigate = useNavigate();

    const { data, loading, error, refetch } = useGetFlightQuery({
        variables: {
            input: {
                flightId
            }
        }
    });

    const [editFlightName, { loading: editingName }] = useEditFlightNameMutation();
    const [editFlightVisibility, { loading: editingVisibility }] = useEditFlightVisibilityMutation();
    const [editFlightDescription, { loading: editingDescription }] = useEditFlightDescriptionMutation();
    const [deleteFlight, { loading: deletingFlight, error: deletingFlightError }] = useDeleteFlightMutation({
        refetchQueries: [
            GetFlightsDocument
        ]
    });

    const sortedPhotos = useMemo(() => {
        if (data?.flight?.photos) {
            return [...data.flight.photos].sort((a, b) => a.added === b.added ? 0 : a.added < b.added ? -1 : 1);
        }
        return [];
    }, [data])

    const markers = useMemo(() => {
        const markerImageWidth = 48;
        const markerImageHeight = 27;
        return sortedPhotos.filter(p => !!p.latitude && !!p.longitude).map(p => ({ 
            latitude: p.latitude!, 
            longitude: p.longitude!,
            image: {
                url: buildURL(p.uri, { w: markerImageWidth, h: markerImageHeight }),
                width: markerImageWidth,
                height: markerImageHeight,
            },
        }))
    }, [sortedPhotos]);

    if (loading) {
        return (
            <Container>
                Loading...
            </Container>
        )
    }

    if (error || !data?.flight) {
        return (
            <Container>
                Error!
            </Container>
        )
    }

    const handleNameChanged = (value: string) => {
        editFlightName({
            variables: {
                input: {
                    flightId,
                    name: value
                }
            }
        })
    }
    
    const handleDescriptionChanged = (value: string) => {
        editFlightDescription({
            variables: {
                input: {
                    flightId,
                    description: value
                }
            }
        })
    }
    
    const handleVisibilityChanged = (value: FlightVisibility) => {
        editFlightVisibility({
            variables: {
                input: {
                    flightId,
                    visibility: value
                }
            }
        })
    }

    const handlePhotoDelete = () => {
        refetch();
    }

    const handleDeleteClicked = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Deleted flight cannot be recovered!\nDo you want to delete this flight?")) {
            deleteFlight({
                variables: {
                    input: {
                        flightId
                    }
                }
            })
            navigate(-1)
        }
    }

    const allowsEditing = data.flight.permissions.allowsEditing;

    return (
        <Container style={{ marginTop: 20 }}>
            <Row>
                <Col>
                    <Name value={data.flight.name} onChange={handleNameChanged} isLoading={editingName} isEditable={allowsEditing} />
                    <Visibility value={data.flight.visibility} onChange={handleVisibilityChanged} isLoading={editingVisibility} isEditable={allowsEditing} />
                    <Description value={data.flight.description} onChange={handleDescriptionChanged} isLoading={editingDescription} isEditable={allowsEditing} />
                    <p>Aircraft: {data.flight.aircraftTitle}</p>
                    {allowsEditing ? <Button variant='danger' disabled={deletingFlight} onClick={() => handleDeleteClicked()}>Delete Flight</Button> : null}
                    {!!deletingFlightError ? <Alert>{deletingFlightError.message}</Alert> : null}
                </Col>
                <Col>
                    <LeafletMap markers={markers} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <PhotoGallery
                        photos={sortedPhotos}
                        onDelete={handlePhotoDelete}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const FlightPage = () => {
    const { id: flightId } = useParams<{ id: string }>();

    if (!flightId) {
        return <>Invalid Flight</>;
    }
    
    return <Content flightId={flightId} />
}

export { FlightPage };

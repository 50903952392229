import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { API_SCOPE } from './constants';

const scopes = [API_SCOPE];

const useAuthentication = () => {
    const [accessToken, setAccessToken] = useState<string | null>(null);

    const { instance, accounts, inProgress } = useMsal();
    useEffect(() => {
        const f = async () => {
            if (inProgress === "none" && accounts.length > 0) {
                // Retrieve an access token
                const response = await instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: scopes
                })
                if (response.accessToken) {
                    setAccessToken(response.accessToken);
                }
            }
        }
        f();
    }, [inProgress, accounts, instance]);

    const login = () => {
        instance.loginRedirect({ scopes, redirectUri: '/' })
    }

    const logout = () => {
        instance.logout()
    }

    return { accessToken, login, logout };
}

export { useAuthentication };
import styled from 'styled-components';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Imgix from 'react-imgix';
import { Button } from 'react-bootstrap';
import { GetPhotosDocument, useDeletePhotoMutation } from 'types';

const StyledPhotoWrapper = styled.div`
display: inline-block;
position: relative;
`

const StyledLuminous = styled.a`
display: block;
`

const StyledTopButtons = styled.div`
position: absolute;
top: 0;
right: 0;
`

const StyledBottomButtons = styled.div`
position: absolute;
bottom: 0;
right: 0;
`

interface Props {
    photo: {
        id: string,
        uri: string,
        title?: string | null | undefined
    };
    fullscreen?: boolean
    onDelete?: (photo: { id: string }) => void;
}

const PhotoDisplay = ({ photo, fullscreen, onDelete }: Props) => {
    const [ deletePhoto, { loading } ] = useDeletePhotoMutation({
        refetchQueries: [
            GetPhotosDocument
        ]
    });

    const handleDelete = async () => {
        if (onDelete) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Deleted photo cannot be recovered!\nDo you want to delete this photo?")) {
                const response = await deletePhoto({
                    variables: {
                        input: {
                            photoId: photo.id
                        }
                    }
                });
                if (!response.errors) {
                    onDelete(photo);
                }
            }
        }
    }

    return (
        <StyledPhotoWrapper>
            <StyledLuminous href={photo.uri} className="luminous">
                <Imgix src={photo.uri} htmlAttributes={{ alt: photo.title || '' }} height={fullscreen ? undefined : 200} />
            </StyledLuminous>
            <StyledTopButtons>
            </StyledTopButtons>
            <StyledBottomButtons>
                {onDelete && <AuthenticatedTemplate>
                    <Button variant="danger" size="sm" disabled={loading} onClick={handleDelete}><i className="bi bi-trash3"></i></Button>
                </AuthenticatedTemplate>}
            </StyledBottomButtons>
        </StyledPhotoWrapper>
    )
}

export { PhotoDisplay };
